import React, { Component } from 'react'
import fetch                from 'isomorphic-fetch'
import {
  isEqual,
  isEmpty
}                           from 'lodash-es'
import {
  usePrevious,
  deepDiff
}                           from 'entrada-ui/utils'
import styles               from './styles.module.scss'

const PreviewLoader = (props) => {
  const [isPreview, setIsPreview] = React.useState(typeof window !== 'undefined' ? (window.location.href.indexOf("preview=true") !== -1) : false)
  const prevProps = usePrevious(props)

  if(isPreview) {
    fetch(`${window.location.origin}/__refresh`, {
      method: 'POST',
      cache: 'no-cache'
    })
  }

  setTimeout(() => setIsPreview(false), 5000)

  return (
    isPreview && isEmpty(deepDiff(prevProps, props)) ?
    <div className={styles.previewLoader}>
      Building preview page...
    </div>
    :
    null
  )
}

export default PreviewLoader
