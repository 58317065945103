import React from 'react'
import PropTypes from 'utils/prop-types'
import cx        from 'utils/classnames'
import styles from './styles.module.scss'

import Item from './Item'

const SimpleList = ({ children, list, className, ...props }) => {

  return (
    <ul className={cx(styles.list, className)} {...props}>
        {children}
    </ul>
  )
}

SimpleList.propTypes = {
  classes: PropTypes.object
}

SimpleList.Item = Item

export default SimpleList;
