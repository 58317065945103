import React                  from 'react'
import Img                    from "gatsby-image"
import cx                     from 'utils/classnames'
import {
  FaArrowLeft,
  FaArrowRight,
}                             from 'react-icons/fa'
import Icon                   from 'entrada-ui/Icon'
import styles                 from './styles.module.scss'

const Slideshow = (props) => {
  const {
    className,
    // If true, then no transition will happen.
    noTransition = false,
    // Time it takes (milliseconds) before next transition starts
    duration = 5000,
    // Determines how long the transition takes
    transitionDuration = 300,
    ViewProps = {},
    children
  } = props

  const [currentIdx, setCurrentIdx] = React.useState(0)
  const transitionTimer = React.useRef(false)

  const goToSlide = (idx) => {
    clearTimeout(transitionTimer.current)
    setCurrentIdx(idx)
  }

  React.useEffect(() => {
    transitionTimer.current = setTimeout(() => {
      if(!noTransition) {
        setCurrentIdx(currentIdx === children.length - 1 ? 0 : currentIdx + 1)
      }
    }, duration)

    return () => clearTimeout(transitionTimer.current)
  }, [children.length, duration, currentIdx, noTransition])

  const onClickLeft = () => goToSlide(currentIdx === 0 ? children.length - 1 : currentIdx - 1)
  const onClickRight = () => goToSlide(currentIdx === children.length - 1 ? 0 : currentIdx + 1)

  return (
    <div
      className={cx(styles.slideshow, className)}
    >
      <div className={cx(styles.view, ViewProps.className)} style={{
        transition: noTransition ? 'none' : `transform ease-in ${transitionDuration}ms`,
        transform:  noTransition ? 'none' : `translateX(-${currentIdx * 100}%)`
      }}>
        {children}
      </div>

      {!noTransition &&
      <button className={styles.btn} onClick={onClickLeft}>
        <Icon className={styles.btnIcon}>
          <FaArrowLeft />
        </Icon>
      </button>
      }

      {!noTransition &&
      <button className={cx(styles.btn, styles.right)} onClick={onClickRight}>
        <Icon className={styles.btnIcon}>
          <FaArrowRight />
        </Icon>
      </button>
      }
    </div>
  )
}

Slideshow.Slide = ({ className, ...props}) => <div className={cx(styles.slide, className)} {...props} />

Slideshow.Img = ({ className, ...props}) => (
  <Img className={cx(styles.img, className)} {...props} />
)

export default Slideshow
