import React                from "react"
import cx                   from 'utils/classnames'
import Icon                 from 'entrada-ui/Icon'
import styles               from '../styles.module.scss'


const SimpleListItem = ({ children, icon, IconProps, ...props }) => (
  <li {...props}>
      <Icon className={cx(
        styles.icon,
        IconProps && IconProps.className
      )}>
          {icon}
      </Icon>

      {children}
  </li>
)

export default SimpleListItem
