import React            from 'react'
import PropTypes        from 'utils/prop-types'
import SimpleList       from 'components/SimpleList'
import styles           from './styles.module.scss'


const Timeline = ({ children, className, ...props }) => {

  return (
    <SimpleList
          className={styles.timeline}
    >
        {children}
    </SimpleList>
  )
}

Timeline.propTypes = {
  classes: PropTypes.object
}

Timeline.Item = ({ icon, time, children }) => (
    <SimpleList.Item
        icon={icon}
        IconProps={{
            className: styles.timelineIcon
        }}
        className={styles.timelineItem}
    >
        <div>
            {time &&
            <h6 className={styles.timelineTime}>{time}</h6>
            }
            {children}
        </div>
    </SimpleList.Item>
)

export default Timeline;
