import React                  from 'react'
import cx                     from 'utils/classnames'
import Typography             from 'entrada-ui/Typography'
import Link                   from 'components/Link'
import Icon                   from 'entrada-ui/Icon'
import {
    FaExternalLinkAlt
}                           from 'react-icons/fa'
import styles                 from './styles.module.scss'

const ExternalBooking = ({
    className,
    externalLink,
    externalLinkNofollow,
    adultPrice
}) => {

    return (
        <div className={cx(styles.bookingWizard, className)}>
            <Typography className={styles.bookingBlockTitle} variant="h1" component="h3">
                from <span className={styles.bookingPrice}>${adultPrice} NZD</span>
            </Typography>

            <hr />

            <a href={externalLink} class="btn btn-primary btn-block btn-lg js-external-link" rel={externalLinkNofollow ? ('sponsored nofollow') : ('sponsored')} target="_blank">
                BOOK NOW
                <Icon className={styles.externalLink}>
                    <FaExternalLinkAlt />
                </Icon>
            </a>

            <div className={styles.formCancellation}>
                By clicking "Book Now", you will be directed to a third-party website, and any bookings made are subject to its terms and conditions, not GreatSights New Zealand's.
            </div>
        </div>
    )
}

export default ExternalBooking
